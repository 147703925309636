<template>
  <div class="account">
    <app-block-flex>
      <app-spacing>
        <app-query-bar
          :queryConfig="queryConfig"
          v-model="queryData"
          @onSearch="fetchAccounts" />
      </app-spacing>
    </app-block-flex>
    <app-block-flex>
      <app-spacing>
        <Button
          v-if="permissions[ACCOUNT_ACTION.CREATE]"
          type="warning"
          @click="() => onAccountAction(ACCOUNT_ACTION.CREATE, true)">
          新建用户
        </Button>
      </app-spacing>
      <app-export-bar
        @onExport="onExport"
        @onExportAll="onExportAll" />
    </app-block-flex>
    <app-data-table
      :columns="ACCOUNT_COLUMNS"
      :data="accounts"
      :allowed-permissions="Object.values(ALLOWED_PERMISSION)"
      v-model="queryData"
      @onPageConfigChange="fetchAccounts">
      <template
        slot-scope="{ row }"
        slot="status">
        {{ STATUS_TEXT[row.status] }}
      </template>
      <app-spacing
        spacing="sm"
        slot-scope="{ row }"
        slot="action">
        <Button
          v-if="permissions[ACCOUNT_ACTION.ROLE_BIND]"
          type="primary"
          @click="() => onAccountAction(ACCOUNT_ACTION.ROLE_BIND, true, row.id)">
          角色分配
        </Button>
        <Button
          v-if="permissions[ACCOUNT_ACTION.RESET]"
          type="primary"
          @click="() => onAccountAction(ACCOUNT_ACTION.RESET, true, row.id)">
          重置密码
        </Button>
        <Button
          v-if="permissions[ACCOUNT_ACTION.STATUS_TOGGLE]"
          :type="row.status === STATUS.ENABLE ? 'error' : 'primary'"
          @click="() => toggleAccountStatus(row.id, row.status)">
          {{ REVERSE_STATUS_TEXT[row.status] }}
        </Button>
      </app-spacing>
    </app-data-table>

    <app-modal
      v-model="accountModalConfig.visible"
      :title="accountActionText"
      @on-ok="onModalConfirm"
      @on-cancel="() => onAccountAction(null, false)">
      <template v-if="accountModalConfig.action === ACCOUNT_ACTION.RESET">
        <p>
          重置后，该用户登录密码将恢复为系统默认登录密码，是否继续操作？
        </p>
      </template>
      <Form
        v-else
        ref="accountForm"
        :model="accountModalData"
        :rules="accountModalRule"
        :label-width="80">
        <template v-if="accountModalConfig.action === ACCOUNT_ACTION.CREATE">
          <FormItem
            label="用户名"
            prop="nickName">
            <Input
              v-model="accountModalData.nickName"
              :placeholder="TIPS.NICKNAME" />
          </FormItem>
          <FormItem
            label="手机号"
            prop="mobile">
            <Input
              v-model="accountModalData.mobile"
              :placeholder="TIPS.MOBILE" />
          </FormItem>
          <FormItem label="性别">
            <Select v-model="accountModalData.sex">
              <Option
                v-for="item in SEX_OPTIONS"
                :value="item.value"
                :key="item.value">
                {{ item.label }}
              </Option>
            </Select>
          </FormItem>
        </template>
        <FormItem
          v-if="accountModalConfig.action === ACCOUNT_ACTION.ROLE_BIND"
          label="角色">
          <CheckboxGroup v-model="accountModalData.roleIds">
            <Checkbox
              v-for="item in accountModalConfig.roleOptions"
              :key="item.id"
              :label="item.id"
              :value="item.id">
              {{ item.name }}
            </Checkbox>
          </CheckboxGroup>
        </FormItem>
      </Form>
    </app-modal>
  </div>
</template>

<script>
import {
  ACCOUNT_ACTION,
  ACCOUNT_ACTION_TEXT,
  ACCOUNT_COLUMNS, SEX,
  SEX_OPTIONS,
  STATUS,
  STATUS_TEXT,
  REVERSE_STATUS_TEXT,
  ALLOWED_PERMISSION,
  TIPS,
} from './constants';
import AccountManageApi from './account-manage.api';
import DataTransferUtil from '../../../utils/data-transfer.util';

export default {
  name: 'account-manage',
  computed: {
    accountActionText() {
      return ACCOUNT_ACTION_TEXT[this.accountModalConfig.action];
    },
  },
  data() {
    return {
      ACCOUNT_ACTION,
      ACCOUNT_COLUMNS,
      SEX_OPTIONS,
      STATUS,
      STATUS_TEXT,
      REVERSE_STATUS_TEXT,
      ALLOWED_PERMISSION,
      TIPS,
      queryConfig: [
        {
          fieldName: 'mobile',
          name: '手机号',
        },
        {
          fieldName: 'nickName',
          name: '用户名',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        mobile: '',
        nickName: '',
      },
      accounts: [],
      accountModalConfig: {
        visible: false,
        action: null,
        roleOptions: [],
      },
      accountModalData: {
        id: NaN,
        nickName: '',
        mobile: '',
        sex: SEX.MALE,
        roleIds: [],
      },
      accountModalRule: {
        nickName: [
          { required: true, message: TIPS.NICKNAME, trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: TIPS.MOBILE, trigger: 'blur' },
        ],
      },
    };
  },
  created() {
    this.fetchAccounts();
  },
  methods: {
    async fetchAccounts() {
      const {
        page, limit, mobile, nickName,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await AccountManageApi.getAccounts(page, limit, nickName, mobile);
      this.accounts = results;
      this.$set(this.queryData, 'count', count);
    },
    async onAccountAction(action = ACCOUNT_ACTION.CREATE, visible = false, accountId = NaN) {
      if (visible) {
        this.$refs.accountForm.resetFields();
      }

      if (action === ACCOUNT_ACTION.ROLE_BIND) {
        this.$set(this.accountModalData, 'id', accountId);
        const roleOptions = await AccountManageApi.getAccountRoles(accountId);
        this.$set(this.accountModalConfig, 'roleOptions', roleOptions);
        const roleIds = roleOptions.filter(({ flag }) => flag).map(({ id }) => id);
        this.$set(this.accountModalData, 'roleIds', roleIds);
      } else {
        this.accountModalData = {
          id: accountId,
          nickName: '',
          mobile: '',
          sex: SEX.MALE,
          roleIds: [],
        };
        this.$set(this.accountModalConfig, 'roleOptions', []);
      }

      this.accountModalConfig = {
        ...this.accountModalConfig,
        visible,
        action,
      };
    },
    async onModalConfirm() {
      if (this.accountModalConfig.action === ACCOUNT_ACTION.RESET) {
        await AccountManageApi.resetPwd(this.accountModalData.id);
        return;
      }

      this.$refs.accountForm.validate(async (valid) => {
        if (!valid) {
          return;
        }

        switch (this.accountModalConfig.action) {
          case ACCOUNT_ACTION.CREATE:
            await this.createAccount();
            break;
          case ACCOUNT_ACTION.ROLE_BIND:
            await this.bindAccountRoles();
            break;
          default:
            break;
        }

        await this.onAccountAction(null);
        await this.fetchAccounts();
      });
    },
    async createAccount() {
      const { nickName, mobile, sex } = this.accountModalData;
      await AccountManageApi.createAccount(nickName, mobile, sex);
    },
    async bindAccountRoles() {
      const { id, roleIds } = this.accountModalData;
      await AccountManageApi.bindAccountRoles(id, roleIds);
    },
    async toggleAccountStatus(id = NaN, status = STATUS.ENABLE) {
      await AccountManageApi.toggleAccountStatus(
        id,
        status === STATUS.ENABLE ? STATUS.DISABLE : STATUS.ENABLE,
      );

      await this.fetchAccounts();
    },
    async onExportAll() {
      await AccountManageApi.exportAllAccounts();
    },
    async onExport() {
      const key = await AccountManageApi.exportAccounts();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>
