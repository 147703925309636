export const ACCOUNT_COLUMNS = [
  {
    title: '用户名',
    key: 'nick_name',
    width: 140,
    fixed: 'left',
  },
  {
    title: '手机号',
    key: 'mobile',
    width: 130,
  }, {
    title: '用户状态',
    key: 'status',
    slot: 'status',
    width: 100,
  }, {
    title: '创建时间',
    key: 'create_time',
    minWidth: 180,
  },
  {
    title: '操作',
    slot: 'action',
    width: 290,
    align: 'center',
    fixed: 'right',
  },
];

export const ALLOWED_PERMISSION = {
  ROLE_BIND: 'permission:sys:account:role:bind',
  STATUS_TOGGLE: 'permission:sys:account:status-toggle',
  RESET: 'permission:sys:account:reset-pwd',
};

export const ACCOUNT_ACTION = {
  CREATE: 'permission:sys:account:add',
  ...ALLOWED_PERMISSION,
};

export const ACCOUNT_ACTION_TEXT = {
  [ACCOUNT_ACTION.CREATE]: '新建用户',
  [ACCOUNT_ACTION.ROLE_BIND]: '角色分配',
};

export const SEX = {
  MALE: 'male',
  FEMALE: 'female',
};

export const SEX_TEXT = {
  [SEX.MALE]: '男',
  [SEX.FEMALE]: '女',
};

export const SEX_OPTIONS = [
  {
    value: SEX.MALE,
    label: SEX_TEXT[SEX.MALE],
  },
  {
    value: SEX.FEMALE,
    label: SEX_TEXT[SEX.FEMALE],
  },
];

export const STATUS = {
  ENABLE: 1,
  DISABLE: 0,
};

export const STATUS_TEXT = {
  [STATUS.ENABLE]: '启用',
  [STATUS.DISABLE]: '停用',
};

export const REVERSE_STATUS_TEXT = {
  [STATUS.ENABLE]: '停用',
  [STATUS.DISABLE]: '启用',
};

export const TIPS = {
  NICKNAME: '请输入用户名',
  MOBILE: '请输入手机号',
};
