import axios from '@/plugins/axios';
import '@/plugins/vuex';
import store from '@/stores/store';
import { SEX } from './constants';

const { loginType } = store.getters;

class AccountManageApi {
  static resetPwd(id) {
    return axios({
      url: `/api/sys/${id}/reset_pwd`,
      method: 'post',
      headers: {
        meta: {
          desc: '重置密码',
        },
      },
    });
  }

  static getAccounts(page = 1, limit = 10, nick_name = '', mobile = '') {
    return axios({
      url: `/api/${loginType}/account/page`,
      data: {
        page, limit, nick_name, mobile,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询用户列表',
        },
      },
    });
  }

  static createAccount(nick_name = '', mobile = '', sex = SEX.MALE) {
    return axios({
      url: `/api/${loginType}/account/add`,
      data: { nick_name, mobile, sex },
      method: 'post',
      headers: {
        meta: {
          desc: '创建账户',
        },
      },
    });
  }

  static getAccountRoles(id = NaN) {
    return axios({
      url: `/api/${loginType}/account/role/list`,
      data: { user_id: id },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '获取用户角色',
        },
      },
    });
  }

  static bindAccountRoles(user_id = NaN, role_ids = []) {
    return axios({
      url: `/api/${loginType}/account/role/bind`,
      data: { user_id, role_ids },
      method: 'post',
      headers: {
        meta: {
          desc: '绑定账户角色',
        },
      },
    });
  }

  static toggleAccountStatus(userId = NaN, status = 0) {
    return axios({
      url: `/api/${loginType}/account/${userId}/status/toggle`,
      data: { status },
      method: 'post',
      headers: {
        meta: {
          desc: '切换账户状态',
        },
      },
    });
  }

  static exportAllAccounts() {
    return axios({
      url: `/api/${loginType}/account/export/all`,
      method: 'post',
      headers: {
        meta: {
          desc: '导出所有',
        },
      },
    });
  }

  static exportAccounts(page = 1, limit = 10, nick_name = '', mobile = '') {
    return axios({
      url: `/api/${loginType}/account/export`,
      data: {
        page, limit, nick_name, mobile,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '条件导出',
        },
      },
    });
  }
}

export default AccountManageApi;
